<template>
  <div class="discounts-login">
    <van-nav-bar class="red-bc">
      <template #title>
        <span :class="!$isLinkGame ? 'yellow-color' : 'white-color'">{{ $t('discounts.promotion') }}</span>
      </template>
    </van-nav-bar>
    <div style="height: 30px"></div>
    <van-pull-refresh v-model="isRefreshLoading" @refresh="onRefresh" :loosing-text="$t('home.releaseToRefresh')"
      :loading-text="this.$t('common.loading')" :pulling-text="$t('common.dropDownRefresh')">
      <div class="discount-item" v-for="item in saleList" :key="item.id" @click="goDetails(item.id, item.title)">
        <div class="title">{{ item.title }}</div>
        <div class="time">
          {{ $t('discounts.date') }}:{{ (item.indate * 1000) | formatTime }}
        </div>
        <div class="image">
          <img :src="item.picture" />
        </div>
        <div class="content">{{ item.desc }}</div>
        <div class="detail">
          <span class="left">{{ $t('discounts.seeDetails') }}</span>
          <i class="right">
            <van-icon name="arrow" />
          </i>
        </div>
      </div>

      <div class="footer" v-if="isFull">{{ $t('blindBox.noMore') }}</div>
    </van-pull-refresh>
  </div>
</template>

<script>
import { getSaleList } from '@/api/sale'
export default {
  name: '',
  components: {},
  props: {},
  data() {
    return {
      pages: 1, // 请求参数
      isRefreshLoading: false, // 是否处于加载状态
      saleList: [],
      total: 1,
    }
  },
  computed: {
    isFull() {
      return this.saleList.length === this.total
    },
  },
  watch: {},
  created() {
    this.getSaleListHandler()
  },
  mounted() { },
  methods: {
    //   获取discounts页面数据
    async getSaleListHandler() {
      const {
        data: { data, ret },
      } = await getSaleList({
        page: this.pages,
      })
      this.isRefreshLoading = false
      if (ret === 1) {
        this.saleList = data.lists
        this.total = data.total
      }
    },

    // 下拉刷新处理函数
    onRefresh() {
      this.page++
      this.getSaleListHandler()
    },

    // 点击每一项跳转
    goDetails(id, title) {
      this.$router.push({
        path: '/discount/detail',
        name: 'discountDetail',
        query: {
          id,
          title,
        },
      })
    },
  },
}
</script>

<style lang="less" scoped>
.discounts-login {
  padding: 0 0 100px 0;

  .discount-item {
    padding: 0 30px;
    align-items: center;
    margin: 10px;
    background-color: #fff;
    border: 1px solid #e8e8e8;
    border-radius: 10px;

    .title {
      font-family: 'PingFang-Medium';
      font-size: 31px;
      color: #242424;
      padding: 18px 0 16px 0;
    }

    .time {
      font-family: 'PingFang-Regular';
      font-size: 25px;
      color: #595959;
      padding-bottom: 16px;
    }

    .image {
      width: 100%;

      img {
        height: 100%;
        width: 100%;
      }
    }

    .content {
      font-family: 'PingFang-Regular';
      padding: 14px 0 12px 0;
      font-size: 25px;
      color: #595959;
    }

    .detail {
      display: flex;
      justify-content: space-between;
      font-size: 25px;
      border-top: 1px solid #e8e8e8;
      padding: 20px 0;

      .left {
        font-family: 'PingFang-Medium';
        color: #242424;
      }

      .right {
        font-family: 'PingFang-Regular';
        color: #595959;
      }
    }
  }

  .footer {
    margin: 30px 0;
    text-align: center;
    height: 40px;
    line-height: 40px;
    font-size: 30px;
    font-family: 'PingFang-Regular';
  }
}

/deep/ .van-nav-bar__title {
  color: #fff;
}
</style>
